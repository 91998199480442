import { cn } from '@sq/components'
import { Supply } from '@sq/data/schemas'
import React, { useCallback, useRef, useState } from 'react'

import SQSkeleton from '@/components/_shared/SQSkeleton/SQSkeleton'
import SupplyCard from '@/components/results/SupplyCard'
import { CardTrackClickType } from '@/components/tracks'

import SQPagination from '../SQPagination/SQPagination'
import styles from './SQSupplyCarousel.module.scss'

interface Props {
    title?: string
    link?: string
    subTitle?: string
    heading?: string
    supplies: Supply[]
    pending?: boolean
    trackOrigin?: CardTrackClickType
}

function HighlightTitle({ titulo, destaque }: { titulo: string; destaque?: string }) {
    const hasHighlight = destaque && titulo.includes(destaque)

    if (hasHighlight) {
        const splitTitle = titulo.split(destaque)
        return (
            <h2 className="mb-2 text-2xl leading-7">
                {splitTitle[0]} <span className="underline">{destaque}</span>
                {splitTitle[1]}
            </h2>
        )
    }

    return <h2 className="mb-2 text-2xl leading-7">{titulo}</h2>
}

// Related to card width + margin
const CARD_OFFSET = 288 + 20
const CARDS_PER_PAGE = 2

const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max)

const Loading = React.memo(() => (
    <>
        {Array.from({ length: 4 }).map((_, id) => (
            <SQSkeleton key={id} height={326} width={340} />
        ))}
    </>
))

Loading.displayName = 'Loading'

function SQSupplyCarousel({ title, heading, subTitle, supplies, pending, link, trackOrigin }: Props) {
    const ref = useRef<HTMLDivElement>(null)
    const [currentScroll, setCurrentScroll] = useState(0)

    const totalPages = Math.round(supplies.length / CARDS_PER_PAGE) - 1
    const currentPage = clamp(Math.round(currentScroll / (CARD_OFFSET * CARDS_PER_PAGE)), 0, totalPages)

    const slide = useCallback(
        (direction: 'left' | 'right') => {
            const currentScrollLeft = ref.current?.scrollLeft ?? 0
            const fixedScrollLeft = Math.floor(currentScrollLeft / CARD_OFFSET) * CARD_OFFSET
            const dir = direction === 'left' ? 1.0 : -1.0
            const newScroll = fixedScrollLeft + CARD_OFFSET * CARDS_PER_PAGE * dir

            ref.current?.scrollTo({
                top: 0,
                left: newScroll,
                behavior: 'smooth',
            })
        },
        [ref]
    )

    const onScroll = useCallback(() => {
        const currentScrollLeft = ref.current?.scrollLeft ?? 0
        setCurrentScroll(currentScrollLeft)
    }, [])

    return (
        <section className="mb-10 xl:px-4">
            {title ? <HighlightTitle titulo={title} destaque={heading} /> : null}
            {subTitle && (
                <h3 className="mb-8 text-sm leading-4 text-sq-gray-700">
                    {subTitle}
                    {link && (
                        <a href={link} className="p-2 font-bold underline decoration-solid">
                            Ver mais
                        </a>
                    )}
                </h3>
            )}
            <div
                ref={ref}
                className={cn(
                    styles.noScrollBar,
                    'grid w-full auto-cols-auto grid-flow-col overflow-x-auto px-1 pb-4 pr-4',
                    'flex flex-row gap-4 xl:gap-5'
                )}
                onScroll={onScroll}
            >
                {pending && <Loading />}
                {supplies?.map((s) => (
                    <SupplyCard
                        variant="fixedWidth"
                        key={s.id}
                        supply={s}
                        trackOrigin={trackOrigin ?? CardTrackClickType.SectionPropertyCard}
                    />
                ))}
            </div>
            <div className="ml-1 mr-4">
                <SQPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    hideArrows={pending || supplies.length < CARDS_PER_PAGE}
                    onNextPage={() => slide('left')}
                    onPreviousPage={() => slide('right')}
                />
            </div>
        </section>
    )
}

export default SQSupplyCarousel
