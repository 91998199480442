'use client'

import { HeartIcon } from '@heroicons/react/24/solid'
import { cn } from '@sq/components'
import { Supply } from '@sq/data/schemas'
import Image from 'next/image'
import Link from 'next/link'
import rulerIcon from 'public/ruler-icon.svg'
import userIcon from 'public/user-icon.svg'
import React, { useCallback } from 'react'
import { useFavoriteSupply } from 'src/hooks/supply'

import { CardTrackClickType, EventType, GetCardEventProperties, track } from '@/components/tracks'
import {
    formatPrice,
    getSupplyTypeDetail,
    isPedestrianFluxHigh,
    toAddressLine1Supply,
    toAddressLine2Supply,
    toArea,
    toTotalPrice,
} from '@/lib/@core/supply/supply.utils'

import SupplyCardCarousel from './SupplyCardCarousel'

type Props = {
    supply: Supply
    trackOrigin?: CardTrackClickType
    disableCarousel?: boolean
    variant?: 'fixedWidth' | 'fullWidth' | 'insideMap'
}

function SupplyCard({ supply, variant = 'fixedWidth', disableCarousel, trackOrigin }: Props) {
    const { isFavorite, toggleFavorite } = useFavoriteSupply(supply)
    const addressLine1 = toAddressLine1Supply(supply)

    const trackSupplyClick = useCallback(() => {
        track(EventType.CardClick, {
            ...GetCardEventProperties(`Supply - ${addressLine1}`, trackOrigin ?? CardTrackClickType.PropertyCard),
            supply,
        })
    }, [supply, addressLine1, trackOrigin])

    const trackCarouselPaginate = useCallback(() => {
        track(EventType.CardClick, {
            ...GetCardEventProperties(
                `Supply - Carousel Paginate - ${addressLine1}`,
                CardTrackClickType.PropertyCardCarouselPaginate
            ),
            supply,
        })
    }, [supply, addressLine1])

    const onClickCarousel = () => {
        trackSupplyClick()
        const url = `${window.location.origin}/imovel/${supply.slug}`
        window.open(url, '_blank')
    }

    return (
        <div
            className={cn(
                'overflow-hidden rounded-2xl border border-sq-gray-200 transition-shadow duration-300 animate-in fade-in pointer-fine:hover:shadow-lg',
                variant === 'fixedWidth' && 'min-w-[288px] max-w-[288px] md:min-h-[362px]',
                variant === 'fullWidth' && 'w-full min-w-[288px] md:min-h-[362px]',
                variant === 'insideMap' && 'h-[292px] w-[264px]'
            )}
        >
            <div className="group relative aspect-[1.53] w-full overflow-hidden">
                <div className="absolute z-10 m-3 rounded-2xl bg-white px-3 py-1 text-xs font-bold">
                    {getSupplyTypeDetail(supply)}
                </div>

                <HeartIcon
                    className={cn(
                        'absolute right-3 top-3 z-10 w-6 stroke-white stroke-2 transition-all duration-300 hover:cursor-pointer',
                        isFavorite ? 'fill-sq-red-500' : 'fill-[#00000080]'
                    )}
                    onClick={toggleFavorite}
                />

                <SupplyCardCarousel
                    images={disableCarousel ? supply.images.slice(0, 1) : supply.images}
                    onClick={onClickCarousel}
                    onPaginate={trackCarouselPaginate}
                />
            </div>
            <Link
                href={`/imovel/${supply.slug}`}
                target="_blank"
                className="flex flex-col p-4"
                onClick={trackSupplyClick}
            >
                <span
                    className={cn(
                        'text-ellipsis text-base text-sq-gray-700',
                        variant === 'insideMap' ? 'line-clamp-1' : 'line-clamp-2'
                    )}
                >
                    {addressLine1}
                </span>
                <span className="text-sm text-sq-gray-900">{toAddressLine2Supply(supply)}</span>
                <span className="mt-1 text-sm font-bold text-sq-gray-900">
                    Valor total: {formatPrice(toTotalPrice(supply))}
                </span>
                <div className="flex flex-row pt-2">
                    <Image src={rulerIcon} alt="Ruler Icon" width={16} height={16} />
                    <span className="ml-1 text-xs text-sq-gray-900">
                        {toArea(supply)}m<sup>2</sup>
                    </span>

                    {isPedestrianFluxHigh(supply) && variant !== 'insideMap' && (
                        <>
                            <Image src={userIcon} alt="People Icon" width={16} height={16} className="ml-4" />
                            <span className="ml-1 text-xs text-sq-gray-900">Alto fluxo de pedestres</span>
                        </>
                    )}
                </div>
            </Link>
        </div>
    )
}

export default SupplyCard
