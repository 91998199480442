'use client'

import { Supply } from '@sq/data/schemas'
import dayjs from 'dayjs'
import { FeatureBundle, LazyMotion, PanInfo, m, useMotionValue } from 'framer-motion'
import { CldImage } from 'next-cloudinary'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { toCldPublicId } from '@/utility/imageUrlHelper'

import CarouselArrow from './CarouselArrow'

const animFeatures = (): Promise<FeatureBundle> =>
    import('@/utility/framerMotionFeatures').then((res: any) => res.default)

const ASPECT_RATIO = 1.53
const IMAGE_WIDTH = 304
const IMAGE_HEIGHT = Math.round(IMAGE_WIDTH / ASPECT_RATIO)
const CLICK_MAX_DELAY = 100 // ms

const swipeConfidenceThreshold = 10000
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity
}

type Props = {
    images: Supply['images']
    onClick?: () => void
    onPaginate?: (direction: number) => void
}

const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max)

const isTouchDevice = (): boolean => (window && 'ontouchstart' in window) || navigator.maxTouchPoints > 0

function SupplyCardCarousel({ images, onClick, onPaginate }: Props) {
    const [currentImageIndex, setCurrentImageIndex] = useState(0)
    const [isTouch, setIsTouch] = useState(false)
    const dragX = useMotionValue(0)
    const mouseDownRef = useRef(dayjs())

    const paginate = useCallback(
        (direction: number) => {
            setCurrentImageIndex((prev) => clamp(prev + direction, 0, images.length - 1))
            onPaginate && onPaginate(direction)
        },
        [onPaginate, images.length]
    )

    const onDragEnd = useCallback(
        (_: PointerEvent, { offset, velocity }: PanInfo) => {
            const swipe = swipePower(offset.x, velocity.x)

            if (Math.abs(swipe) > swipeConfidenceThreshold) {
                paginate(Math.sign(-swipe))
            }
        },
        [paginate]
    )

    const onMouseDown = () => {
        mouseDownRef.current = dayjs()
    }

    const onMouseUp = () => {
        const mouseUpTime = dayjs()
        const timeDiff = mouseUpTime.diff(mouseDownRef.current, 'ms')

        if ((isTouch && timeDiff < CLICK_MAX_DELAY) || !isTouch) {
            onClick && onClick()
        }
    }

    useEffect(() => {
        // This should run on the client only
        setIsTouch(isTouchDevice())
    }, [])

    return (
        <>
            <CarouselArrow disabled={currentImageIndex <= 0} variant="left" onClick={() => paginate(-1)} />

            <CarouselArrow
                disabled={currentImageIndex >= images.length - 1}
                variant="right"
                onClick={() => paginate(1)}
            />

            {images.length > 1 && (
                <div className="absolute bottom-2 left-1/2 z-10 -translate-x-1/2 select-none rounded-2xl bg-sq-gray-900 bg-opacity-90 px-2 py-1 text-xs text-white">
                    {currentImageIndex + 1} / {images.length}
                </div>
            )}

            <div className="w-full overflow-hidden bg-sq-gray-100 transition-transform duration-500 hover:scale-[1.05] active:cursor-grabbing">
                <LazyMotion features={animFeatures} strict>
                    <m.div
                        drag={isTouch ? 'x' : false}
                        dragConstraints={{ left: 0, right: 0 }}
                        dragElastic={{
                            left: currentImageIndex >= images.length - 1 ? 0 : 0.3,
                            right: currentImageIndex <= 0 ? 0 : 0.3,
                        }}
                        onMouseDown={onMouseDown}
                        onMouseUp={onMouseUp}
                        onDragEnd={onDragEnd}
                        className="flex flex-row hover:cursor-pointer"
                        style={{ x: dragX }}
                        animate={{
                            translateX: `-${currentImageIndex * 100}%`,
                        }}
                        transition={{
                            x: { type: 'spring', stiffness: 300, damping: 60 },
                        }}
                    >
                        {images.map((image, index) =>
                            [currentImageIndex - 1, currentImageIndex, currentImageIndex + 1].includes(index) ? (
                                <CldImage
                                    key={image.url}
                                    src={toCldPublicId(image.url)}
                                    alt={image.alt}
                                    width={IMAGE_WIDTH}
                                    height={IMAGE_HEIGHT}
                                    aspectRatio={ASPECT_RATIO}
                                    crop={'fill'}
                                    draggable={false}
                                    className={'w-full shrink-0 select-none bg-sq-gray-100'}
                                    unoptimized
                                />
                            ) : (
                                <div key={image.url} className="size-full shrink-0 bg-black">
                                    {index}
                                </div>
                            )
                        )}
                    </m.div>
                </LazyMotion>
            </div>
        </>
    )
}

export default SupplyCardCarousel
