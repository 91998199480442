import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { cn } from '@sq/components'
import React from 'react'
import { ComponentProps } from 'react'

type Props = ComponentProps<'button'> & {
    variant: 'left' | 'right'
}

const CarouselArrow = React.memo(({ variant, disabled, ...props }: Props) => (
    <button
        {...props}
        className={cn(
            'absolute top-[50%] z-10 flex size-[32px] items-center justify-center rounded-full border-none bg-[#FFFFFFCC] transition-opacity duration-300',
            variant === 'right' ? 'right-2' : 'left-2',
            disabled ? 'pointer-events-none opacity-0' : 'opacity-0 group-hover:opacity-100',
            props.className
        )}
    >
        {variant === 'right' ? (
            <ChevronRightIcon width={15} className="stroke-black stroke-2" />
        ) : (
            <ChevronLeftIcon width={15} className="stroke-black stroke-2" />
        )}
    </button>
))

CarouselArrow.displayName = 'CarouselArrow'

export default CarouselArrow
